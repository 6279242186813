<template>
  <v-container fluid class="px-0 py-0">
    <address-table
      :headers="headers"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size)"
    />
  </v-container>
</template>

<script>
import AddressTable from "./table";
import { Errors } from "@/utils/error";
import { getAddresslistPredefinedData } from "@/api/datafeeds/addresslist/predefined";

export default {
  components: {
    AddressTable,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      headers: [
        { text: "IP", value: "ip" },
        {
          text: "Col1",
          value: "col1",
        },
        { text: "Col2", value: "col2" },
      ],
      data: [],
      loading: true,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getAddresslistPredefinedData(this.id, "v6", newPage, size)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>
